import React from "react";
import "./App.css";

function App() {
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const getNextSeason = () => {
    if (month < 3) return `Coming Spring ${year}`;
    else if (month < 6) return `Coming Summer ${year}`;
    else if (month < 9) return `Coming Fall ${year}`;
    else return `Coming Early ${year + 1}`;
  };

  const nextSeason = getNextSeason();

  return (
    <div className="App">
      <div className="hero flex-wrap vertical">
        <img
          alt="Sadie Starlight Character"
          src="/img/sadie.jpg"
          style={{ height: "19rem", width: "auto", display: "block" }}
        />
        <h3 className="sans">{nextSeason}</h3>
        <img
          alt="Sadie Starlight Logo"
          src="/img/sadie-starlight.jpg"
          style={{ maxWidth: "100vw" }}
        />
        <h3>
          <a
            aria-label="Link to SaraLaCombe.com"
            href="https://saralacombe.com"
          >
            By Sara LaCombe.
          </a>
        </h3>
      </div>
      <div className="hearts mb-1">
        <div className="pad-m">
          <fieldset className="container narrow">
            <legend className="center">
              <h5 className="bold regal">
                <img
                  alt="Sara LaCombe's Logo"
                  src="https://saralacombe.com/img/logo-gold.png"
                  style={{
                    verticalAlign: "middle",
                    height: "3.5rem",
                    padding: "0 .5rem"
                  }}
                />
                Sara's First Children's Book
                <img
                  alt="Sara LaCombe's Logo"
                  src="https://saralacombe.com/img/logo-gold.png"
                  style={{
                    verticalAlign: "middle",
                    height: "3.5rem",
                    padding: "0 .5rem"
                  }}
                />
              </h5>
            </legend>
            <div className="pad-m">
              <h4 className="lovely bold pad-xs">The Adventures Of</h4>
              <h2 className="lovely bold pad-xs">Petunia The Goat</h2>
              <h5 className="lovely bold">Volume 1</h5>
            </div>
            <h5 className="regal">Coming Soon</h5>
          </fieldset>
        </div>
      </div>
    </div>
  );
}

export default App;
